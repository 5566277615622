import type { CountryCode } from '@yonderlabs-packages/schemas-country';

import { analytics } from '~/entry.client';
import { useAuthenticatedUserData } from '~/src/shared/hooks';

type PeopleVisitedEventPayload = {
  people_count: number;
};

type InvitePersonPersonalInformationEventPayload = {
  selected_country: string;
};

type InvitePersonEmploymentInformationEventPayload = {
  selected_currency: string;
} & InvitePersonPersonalInformationEventPayload;

type PackagesEventPayload = {
  package_count: number;
};

type PackageEventPayload = {
  package_name: string;
  package_country: string;
  package_status: 'Active' | 'Inactive';
};

type PackageAllowanceEventPayload = {
  package_band: 'Low' | 'Standard' | 'Competitive' | 'Custom';
};

type PackageSummaryEventPayload = {
  package_country_code: string;
  package_country_name: string;
  package_people_count: number;
  package_health_allowance: number;
  package_retirement_allowance: number;
};

type InterestedInBenefitEventPayload = {
  benefit: 'Life' | 'Health';
  country: CountryCode;
};

export type AnalyticsEvent =
  | {
      type: 'Web: Login Page - Visited';
    }
  | {
      type: 'Web: Login - Work Email Input - Clicked';
    }
  | {
      type: 'Web: Login - Password Input - Clicked';
    }
  | {
      type: 'Web: Login - Forgot Password Link - Clicked';
    }
  | {
      type: 'Web: Login - Button - Clicked';
    }
  | {
      type: 'Web: Login - Sign Up Link - Clicked';
    }
  | {
      type: 'Web: Forgot Password Page - Visited';
    }
  | {
      type: 'Web: Forgot Password - Work Email Input - Clicked';
    }
  | {
      type: 'Web: Forgot Password - Button - Clicked';
    }
  | {
      type: 'Web: Reset Password Page - Visited';
    }
  | {
      type: 'Web: Reset Password - New Password Input - Clicked';
    }
  | {
      type: 'Web: Reset Password - Confirm Password Input - Clicked';
    }
  | {
      type: 'Web: Reset Password - Button - Clicked';
    }
  | {
      type: 'Web: Onboarding Page - Visited';
    }
  | {
      type: 'Web: Onboarding - Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Contractor Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Employee Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Company Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Employee Page - Visited';
    }
  | {
      type: 'Web: Onboarding - Employee - Support Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account Page - Visited';
    }
  | {
      type: 'Web: Onboarding - Account - First Name Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account - Last Name Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account - Work Email Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account - Password Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account - Continue Registration Button - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account - Sign In Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account - Terms of Service Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Account - Privacy Policy Link - Clicked';
    }
  | {
      type: 'Web: Onboarding - Email Verification Page - Visited';
    }
  | {
      type: 'Web: Onboarding - Email Verification - Resend Email Button - Clicked';
    }
  | {
      type: 'Web: Onboarding - Profile Page - Visited';
    }
  | {
      type: 'Web: Onboarding - Profile - Legal Name Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Profile - Incorporation Country Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Profile - Type Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Profile - Size Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Profile - Registration Number Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Profile - VAT Number Input - Clicked';
    }
  | {
      type: 'Web: Onboarding - Profile - Continue Button - Clicked';
    }
  | {
      type: 'Web: People Visited';
      payload: PeopleVisitedEventPayload;
    }
  | {
      type: 'Web: People - Add Person Button - Clicked';
      payload: PeopleVisitedEventPayload;
    }
  | {
      type: 'Web: People - Upload .xls Button - Clicked';
      payload: PeopleVisitedEventPayload;
    }
  | {
      type: 'Web: People - Edit Person Button - Clicked';
      payload: PeopleVisitedEventPayload;
    }
  | {
      type: 'Web: People - Upload .xls - Upload File Button - Clicked';
    }
  | {
      type: 'Web: People - Upload .xls - Download Template File Link - Clicked';
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - First Name Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Last Name Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Work Email Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Address Line 1 Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Sex Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Date of Birth Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Country Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Postal Code Input - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Personal Information - Button - Clicked';
      payload: InvitePersonPersonalInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Employment Information - Employee Number Input - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Employment Information - Role Input - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Employment Information - Start Date Input - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Employment Information - End Date Input - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Employment Information - Salary Currency Input - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Employment Information - Annual Salary Input - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Employment Information - Tax ID Input - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Invite New Member - Button - Clicked';
      payload: InvitePersonEmploymentInformationEventPayload;
    }
  | {
      type: 'Web: People - Edit Member - Deactivate Member Link - Clicked';
    }
  | {
      type: 'Web: People - Edit Member  - Role Input - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Annual Salary Input - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Salary Currency Input - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Start Date Input - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Date of Birth Input - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Tax ID Input  Input - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Button - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Plans Link - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Remove from Plan Link - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Deactivate Member - Cancel Button - Clicked';
    }
  | {
      type: 'Web: People - Edit Member - Deactivate Member - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create a Package Button - Clicked';
      payload: PackagesEventPayload;
    }
  | {
      type: 'Web: Packages - Package - Clicked';
      payload: PackageEventPayload;
    }
  | {
      type: 'Web: Packages - Create Package Name - Package Name Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Name Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Country - Package Name Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Country- Package Country Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Country- Organization Registration Number Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Country - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Options - Retirement Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Options - Health Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Options - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Allowances  - Band Input - Clicked';
      payload: PackageAllowanceEventPayload;
    }
  | {
      type: 'Web: Packages - Create Package Allowances - Health Input - Clicked';
      payload: PackageAllowanceEventPayload;
    }
  | {
      type: 'Web: Packages - Create Package Allowances - Retirement Input - Clicked';
      payload: PackageAllowanceEventPayload;
    }
  | {
      type: 'Web: Packages - Create Package Allowances - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Billing Entity - Select Billing Entity - Billing Entity Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Billing Entity - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Add Signatories - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package People - Search Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package People - Person Checkbox Input - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package People - Skip Link - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package People - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Summary - Button - Clicked';
      payload: PackageSummaryEventPayload;
    }
  | {
      type: 'Web: Packages - Create Package Accept Terms & Conditions - Kota Privacy Notice Link - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Accept Terms & Conditions - Irish Life Privacy Notice Link - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Accept Terms & Conditions - Irish Life Master Trust Privacy Notice Link - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Accept Terms & Conditions - Checkbox - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Accept Terms & Conditions - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Create Package Sign Required Documents - Button - Clicked';
      payload: PackageSummaryEventPayload;
    }
  | {
      type: 'Web: Packages - Edit Package - Add Members - Search Input - Clicked';
    }
  | {
      type: 'Web: Packages - Edit Package - Add Members - Person Checkbox Input - Clicked';
    }
  | {
      type: 'Web: Packages - Edit Package - Add Members - Button - Clicked';
    }
  | {
      type: 'Web: Packages - Edit Package - Remove Members - Search Input - Clicked';
    }
  | {
      type: 'Web: Packages - Edit Package - Remove Members - Person Checkbox Input - Clicked';
    }
  | {
      type: 'Web: Packages - Edit Package - Remove Members - Button - Clicked';
    }
  | {
      type: 'Web: Billing - Add Payment Method Button - Clicked';
    }
  | {
      type: 'Web: Billing - Update Payment Method Button - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Details Button - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Details Link - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Details Button - Clicked';
    }
  | {
      type: 'Web: Billing - Add Payment Method - Button - Clicked';
    }
  | {
      type: 'Web: Billing - Update Payment Method - Button - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Name Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Email Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Tax ID Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Registration Number Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Address Line 1 Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Address Line 2 Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - State Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Country Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Postal Code Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Billing Information - Button - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Name Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Email Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Tax ID Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Address Line 1 Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Address Line 2 Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - State Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Country Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Postal Code Input - Clicked';
    }
  | {
      type: 'Web: Billing - Update Billing Information - Button - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity Button - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Select Country - Button - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Select Payment Juristiction - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Select Country - Billing Entity Country Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Name Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Email Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Tax ID Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Address Line 1 Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Address Line 2 Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - State Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Country Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Postal Code Input - Clicked';
    }
  | {
      type: 'Web: Billing - Create Billing Entity - Add Billing Information - Button - Clicked';
    }
  | {
      type: 'Web: Billing - Add Payment Method - Select Billing Entity - Billing Entity Input - Clicked';
    }
  | {
      type: 'Web: Billing - Add Payment Method - Select Billing Entity - Create New Billing Entity Link - Clicked';
    }
  | {
      type: 'Web: Billing - Add Payment Method - Select Billing Entity - Button - Clicked';
    }
  | {
      type: 'Web: Interested in Benefit';
      payload: InterestedInBenefitEventPayload;
    };

export type getEventArgs<T extends AnalyticsEvent> = T extends {
  type: infer Type;
  payload: infer TPayload;
}
  ? [type: Type, payload: TPayload]
  : T extends { type: infer Type }
    ? [type: Type]
    : never;

export const useEventTracking = () => {
  const { organisation } = useAuthenticatedUserData();

  const trackEvent = <T extends AnalyticsEvent>(...args: getEventArgs<T>) => {
    const [type, payload] = args;

    let analyticsPayload = payload || {};

    if (organisation) {
      analyticsPayload = {
        ...analyticsPayload,
        org_name: organisation.name,
      };
    }

    void analytics.track(type, analyticsPayload || {}, {
      integrations: {
        All: false,
        'Mixpanel (Actions)': true,
        Intercom: false,
      },
    });
  };

  return { trackEvent };
};
